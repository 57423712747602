/*styles for Footer*/
.custom-footer {
    background-color: #06400e;
    color: #cf964c;
    padding: 10px 0;
    text-align: center;
    bottom: 0;
}
  
.footer-link {
    color: #cf964c;
    text-decoration: none;
    margin-right: 20px;
}

.footer-link:hover {
    color: #dd8f28;
    text-decoration: none;
}

.link-contact {
    position: relative;
    color: #cf964c;
    border: none;
    text-decoration: none;
}

.link-contact:hover {
    text-decoration: none;
    color: #cf964c;
}

.link-contact::before,
.link-contact::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #cf964c;
    left: 0;
    transition: all 0.5s;
    transform: scaleX(0);
    transform-origin: center;
}

.link-contact::before {
  top: -2px; 
}

.link-contact::after {
  bottom: -2px; 
}

.link-contact:hover::before,
.link-contact:hover::after {
  transform: scaleX(1); 
}

.active {
    position: relative;
  }
  
.link-contact .active::before,
.link-contact .active::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #cf964c; /* Color of the lines for active item */
    left: 0;
    transform: scaleX(1);
    transform-origin: center;
}

.link-contact .active::before {
    top: -3px;
}

.link-contact .active::after {
    bottom: -4px;
}

.footer-link-mobile {
    color: rgb(204, 230, 209);
    text-decoration: none;
    font-weight: bolder;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-right: 20px;
}

.hr-footer {
    width: 100%;
    border: 1px solid rgb(204, 230, 209);

}



@media (max-width: 480px) {
    .custom-footer {
        position: fixed;
        z-index: 1000;
        width: 100%;
    }
}