/*styles for HoursAndLocations*/
.center {
    color: #06400e;
    font-family: 'Times New Roman', Times, serif;
    padding-bottom: 20px;
    text-align: center;
}

.center h1 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 50px;
}

.center h5 {
    margin-top: 20px;
}

.center p {
    margin-bottom: 5px;
}

.center .location {
    color: #0d5e18;
}

.center .location:hover {
    color: #06400e;
}