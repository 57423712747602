/*styles for Contact*/
.contact {
    color: #06400e;
    font-family: 'Times New Roman', Times, serif;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.contact h1 {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 50px;
}

.contact-form {
    width: 500px;
}
 
.contact-form .form-control {
    padding: 25px 10px 25px 10px;
}

.contact .btn-center {
    display: flex;
    justify-content: center;
}

.contact .btn-center .btn-send {
    position: relative;
}

.contact .btn-center .btn-send::before,
.contact .btn-center .btn-send::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #06400e; /* Color of the lines for active item */
    left: 0;
    transform: scaleX(1);
    transform-origin: center;
}

.contact .btn-center .btn-send::before {
    top: -2px;
}

.contact .btn-center .btn-send::after {
    bottom: -2px;
}

.contact .btn-center .btn-send:hover {
    background-color: #06400e;
    color: #cf964c;
    border-radius: 0;
}


@media (max-width: 768px) {
    .contact h1 {
     font-size: 30px;
    }
     
 }


 @media (max-width: 480px) {
    .contact-form {
        padding-left: 10px;
        padding-right: 10px;
    }
 }