/*styles for Header*/
.header-mobile {
    display: none;
}

.link-as-text {
    font-family: 'Times New Roman', Times, serif;
    text-decoration: none; /* Remove underlines */
    color: inherit; /* Inherit the text color from the parent element */
    cursor: pointer; /* Change the cursor style to indicate it's clickable */
  }

.link-as-text:hover {
    color:black;
    text-decoration: none;
}

.custom-bg-gold {
    background-color: #cf964c;
}

.custom-text-green {
    color: #06400e;
}

.phone:hover {
    cursor: pointer;
    color: black;
}

@media (max-width: 768px) {

}

@media (max-width: 480px) {
    .header-laptop {
        display: none;
    }

    .header-mobile {
        display: block;
    }
}