/*styles for About*/
.about {
    margin-top: 30px;
    color: #06400e;
    font-family: 'Times New Roman', Times, serif;
    padding-bottom: 90px;
    text-align: center;
}

.about .about-descrip {
    width: 50%;
    margin: auto;
}

.about-h2 {
    padding-top: 60px;
    padding-bottom: 20px;
}

.about .image-group {
    margin: 10px 30px 10px 30px;
}

.about .image-group img {
    height: 300px;
}

.about .image-group h5{
    padding-top: 20px;
}

.about .image-group p {
    margin-bottom: 30px;
}


/*medium - larger tablets and smaller desktops*/
@media (max-width: 992px) {
    .about .about-descrip {
        width: 70%;
    }
    
    .about .about-h2 {
        font-size: 30px;
    }
}

/*small -tablets*/
@media (max-width: 768px) {

    .about .about-h2 {
        font-size: 40px;
    }

    .about .about-descrip {
        width: 90%;
    }
    
}


/*extra small - mobile devices*/
@media (max-width: 576px) {
    .about .about-descrip {
        width: 100%;
    }
}